<template>
  <div class="quote-carousel">
    <WrapperSwiper class="flex-col cntr" :swiper-options="swiperOptions">
      <div class="logo" />
      <div v-if="title" class="header-text">
        {{ title }}
      </div>
      <swiper-slide v-for="(quote, index) in quotes" :key="index + 'quote'">
        <div class="quote flex-col">
          "{{ quote.quote }}"
          <img
            v-if="getCloudinaryId(quote.social_image)"
            :src="resize(quote.social_image)"
            :alt="quote.quote"
            class="quote-img mgn-top-1-pt5"
          />
        </div>
      </swiper-slide>
      <div class="controls flex-inline cntr">
        <div class="swiper-button-prev-quote">
          <ArrowLeftLine />
        </div>
        <div ref="quoteCarouselPagination" class="swiper-pagination-quote flex-inline" />
        <div class="swiper-button-next-quote">
          <ArrowRightLine />
        </div>
      </div>
    </WrapperSwiper>
  </div>
</template>

<script setup lang="ts">
import { SwiperOptions } from 'swiper/types';
import { useCloudinary } from '~/composables/cloudinary';
import { getAssetPublicId } from '~/util/contentstack/csHelpers';

const cloudinary = useCloudinary();

defineProps({
  quotes: {
    type: Array as () => any[],
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
});

const quoteCarouselPagination = ref<HTMLDivElement | null>(null);
const swiperOptions: SwiperOptions = {
  navigation: {
    nextEl: '.swiper-button-next-quote',
    prevEl: '.swiper-button-prev-quote',
  },
  centeredSlides: true,
  loop: false,
  a11y: {
    enabled: true,
  },
  pagination: {
    el: quoteCarouselPagination as any,
    type: 'bullets',
    clickable: true,
  },
};

function resize(img: any): string | undefined {
  const id = getCloudinaryId(img);
  return id ? cloudinary.bynderToCloudinaryResize(id, 150, 50, 2, 'fit') : undefined;
}
function getCloudinaryId(img: any): string | undefined {
  return getAssetPublicId(img);
}
</script>

<style lang="scss" scoped>
.quote-carousel {
  background-color: $color-primary-500;
  padding: 3rem 0;
}
.logo {
  @include local-mixins.backgroundImage(local-functions.svg-img-source('white-circle-logo.svg'), 48px, 50px);
  margin-bottom: 1.5rem;
}
.header-text,
.quote {
  font-weight: 500;
  color: $color-neutral-white;
}
.header-text {
  text-transform: uppercase;
  font-size: #{local-functions.rem-calc(19)};
  margin-bottom: 2.75rem;
}
.quote {
  font-size: #{local-functions.rem-calc(30)};
  text-align: center;
  align-items: center;
  @include local-mixins.tablet_and_mobile {
    margin: 0 1.5rem;
  }
}
.quote-img {
  width: auto;
  height: auto;
  max-height: 150px;
  max-width: 150px;
}
.controls {
  margin-top: 2rem;
}
swiper-container {
  .swiper-wrapper,
  .quote {
    max-width: #{local-functions.rem-calc(789)};
  }
}
.swiper-button-next-quote,
.swiper-button-prev-quote {
  height: 1rem;
  cursor: pointer;
  :deep(svg) {
    fill: $color-neutral-white;
    width: 1rem;
    height: 1rem;
  }
}
.swiper-button-prev-quote {
  margin-right: 1rem;
}
.swiper-button-next-quote {
  margin-left: 1rem;
}
@include local-mixins.desktop {
  .logo {
    @include local-mixins.backgroundImage(local-functions.svg-img-source('white-circle-logo.svg'), 72px, 75px);

    margin-bottom: 2.6rem;
  }
  .header-text {
    font-size: 1.25rem;
  }
  .quote {
    font-size: #{local-functions.rem-calc(38)};
  }
}
</style>
